body, input, button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #F5F5F5;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--title-color);
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

