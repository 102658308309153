.home-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .link-to-survey-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    h4 {
      text-align: center;
    }
    
    .action-container{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      min-height: 30vh;
      div {
        h6 {
          padding-bottom: 5px;
          text-align: center;
        }
      }

      .disable-link {
        background-color: #D3D3D3;
        border: none;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .thanks-section {
    text-align: center;
    font-size: 26px;
  
    .site-link {
      padding-top: 15px;
      text-decoration: none;
      color: black;
      font-size: 15px;
      font-weight: 900;
    }
  
    .site-link-text {
      color: black;
      font-size: 15px;
    }
  }
  
  .logo {
    width: 50vw;
    height: 45vh;
  }
  
  @media (min-width: 768px) {
    .logo {
      width: 15vw;
      height: 40vh;
    }
  }
}
